import { TripsOfferData } from '@vakantiesnl/services/src/context/useAccommodationContext';
import { Search, Awards, Nbc } from '@vakantiesnl/types';

import {
	Geo,
	Recommendation,
	ReviewCalculations,
	FetchedFact,
	FormattedFacts,
	GeoEntityType,
	Media,
	MappedBasicHit,
} from '.';

export interface RawAccommodationView {
	accommodationType: string;
	adlinkCount?: number;
	awards: Awards.AwardsData;
	address: Address;
	bookingInfo?: BookingInfo;
	contactInformation: Nbc.RawContactInformation;
	defaultMedia?: Media;
	defaultPrice?: number;
	descriptions: Descriptions;
	description?: Description;
	enoughForumMessagesToIndex?: boolean;
	enoughTipsToIndex?: boolean;
	facts: FetchedFact[];
	giataId: string | null;
	geo?: Geo;
	heliosId: number;
	heliosUrl: string;
	id: string;
	isBookable: boolean;
	isBookableQenner: boolean;
	locales: Locales;
	name: string;
	parentId: string;
	parents: GeoEntityType[];
	parentIds: ParentIds;
	photosCount?: number;
	premiumAdlinkEnabled: boolean;
	premiumAdlinkUrl?: string;
	filters: Filters;
	distanceFilters: DistanceFilters;
	recommendations?: Recommendation[];
	reviewCalculations: Partial<ReviewCalculations>;
	stars: number;
	status?: Status;
	traveledWith: TraveledWith;
	_createdAt: Date;
	_updatedAt: Date;
	_index: string;
	_type: string;
	_id: string;
	_score: number | null;
	topImages?: Search.TopImageItem[];
}

export type AccommodationView = MappedBasicHit & {
	contactInformation: Nbc.ContactInformation;
	description?: Description;
	facts: FormattedFacts[];
	geo: GeoObject;
	premiumAdlinkEnabled: boolean;
	premiumAdlinkUrl?: string;
	filters: Filters;
	distanceFilters: DistanceFilters;
	offers?: TripsOfferData;
};

interface GeoObject {
	latitude?: number;
	longitude?: number;
}

export interface MappedAccommodationData {
	hits: AccommodationView[];
}

export interface Total {
	value: number;
	relation: string;
}

export enum FILTER_OPTIONS {
	wifi = 'wifi',
	airco = 'airco',
	playground = 'playground',
	entertainment = 'entertainment',
	restaurant = 'restaurant',
	accessibility = 'accessibility',
	pets_allowed = 'pets_allowed',
	pool = 'pool',
	jacuzzi = 'jacuzzi',
	childrens_pool = 'childrens_pool',
	waterpark = 'waterpark',
	family_room = 'family_room',
	baby_cot = 'baby_cot',
	kids_club = 'kids_club',
	gameroom = 'gameroom',
	sport_facilities = 'sport_facilities',
}
export type Filters = Array<FILTER_OPTIONS>;

export enum DISTANCE_FILTER_OPTIONS {
	on_the_beach = 'on_the_beach',
	less_than_1km_to_the_beach = 'less_than_1km_to_the_beach',
	less_than_1km_to_the_center = 'less_than_1km_to_the_center',
}
export type DistanceFilters = Array<DISTANCE_FILTER_OPTIONS>;

export interface Room {
	imageLinks: string[];
	name: string;
	description: string[];
}

export interface Address {
	zipcode: string;
	street: string;
}

export interface BookingInfo {
	defaultPrice: number;
	days: number;
	airport: string;
	departureDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Descriptions {}

export interface Description {
	html: string;
}

export interface Locales {
	ALL: number;
	[key: string]: number;
}

export interface LpsaScore {
	area: number;
	country: number;
	general: number;
	dest: number;
}

export interface ParentIds {
	continentId?: string;
	regionId: string;
	cityId: string;
	countryId: string;
}

export interface Status {
	noIndexed: boolean;
}

export interface TraveledWith {
	FAMILY_WITH_YOUNG_CHILDREN?: number;
	OTHER?: number;
	ALL: number;
	FRIENDS?: number;
	SINGLE?: number;
	COUPLE: number;
	BUSINESS?: number;
	FAMILY_WITH_OLDER_CHILDREN?: number;
	FAMILY?: number;
}

export interface AccommodationViewQuery {
	query: {
		term: {
			id: string;
		};
	};
}
