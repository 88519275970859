import { Search } from '@vakantiesnl/types';

export type FlashDealsResponse = {
	flash_deals: RawFlashDeal[];
	flash_deals_count: number;
};

export type RawFlashDeal = {
	accommodation: Search.RawCompactAccommodationItem;
	flash_deal: RawFlashDealItem;
};

export type RawFlashDealItem = {
	airlines: string[] | null;
	departure_airports: string[] | null;
	departure_date_from: string;
	departure_date_to: string;
	discount_amount: number;
	discount_amount_type: DISCOUNT_TYPE;
	duration: number;
	expiration_date: string;
	giata_id: number;
	id: string;
	mealplans: string[] | null;
	room_types: string[] | null;
	valid_from: string;
};

export enum DISCOUNT_TYPE {
	PERCENTAGE = 'percentage',
	FIXED = 'fixed',
}

export type FlashDeal = {
	accommodation: Search.CompactAccommodationItem;
	flashDeal: FlashDealItem;
};

export type FlashDealItem = {
	airlines?: string[];
	departureAirports?: string[];
	departureDateFrom: string;
	departureDateTo: string;
	discountAmount: number;
	discountAmountType: DISCOUNT_TYPE;
	duration: string;
	expirationDate: string;
	giataId: number;
	id: string;
	mealplans?: string[];
	roomTypes?: string[];
	validFrom: string;
};
