export enum FilterId {
	departureAirports = 'departure_airports',
	themes = 'themes',
	countrySlugs = 'country_slugs',
	durations = 'durations',
	durationRange = 'duration_range',
	regionSlugs = 'region_slugs',
	citySlugs = 'city_slugs',
	departureOffset = 'departure_offset',
	mealplans = 'mealplans',
	chains = 'chains',
	tourOperator = 'tourOperator',
}

export type Option = {
	id: string;
	title: string;
	value?: boolean;
};

export type Filters = {
	departure_airports?: string[];
	themes?: string[];
	attributes?: string[];
	durations_flight?: number[];
	geo_filters?: {
		country_slugs?: string[];
		region_slugs?: string[];
		city_slugs?: string[];
	};
	transport?: string;
	mealplans?: string[];
	distances?: string[];
	flight_provider?: string;
	airlines?: string[];
	tour_operators?: string[];
	domains?: string[];
	awards?: string[];
	accommodationType?: string[];
	filters?: string[];
	distanceFilters?: string[];
	rating?: string;
	chains?: number[];
};

export type ZooverCompositeFilters = {
	departure_airports?: string[];
	themes?: string[];
	durations_flight?: number[];
	geo_filters?: {
		country_ids?: string[];
		region_ids?: string[];
		city_ids?: string[];
	};
	stars?: string[];
	transport?: string;
	mealplans?: string[];
	distances?: string[];
	flight_provider?: string;
	airlines?: string[];
	tour_operators?: string[];
	domains?: string[];
	awards?: string[];
	accommodationType?: string[];
	filters?: string[];
	distanceFilters?: string[];
	rating?: string;
	chains?: number[];
};
