import { Elastic, Search } from '@vakantiesnl/types';

export interface RawSearchData {
	aggregations: Record<string, Record<string, number>>;
	hits: RawSearchHit[];
	total: number;
}

export interface RawSearchHit extends Elastic.RawBasicHit {
	pw_result: Search.RawCompactAccommodationItem | null;
}

export interface SearchResponseBody {
	data: RawSearchData;
	success: boolean;
	took: number;
}

export enum GEO_FILTER_MAP {
	CITY = 'cities',
	REGION = 'regions',
	COUNTRY = 'country_codes',
}

export type GeoFilters = Record<GEO_FILTER_MAP, number | string | string[]>;

export type AggregationsItem = {
	key: string;
	doc_count: number;
};

export type AggregationType = keyof RawCompositeSearchData['aggregations'];

export interface RawCompositeSearchData {
	aggregations: {
		accommodationType: AggregationsItem[];
		rating: AggregationsItem[];
		stars: AggregationsItem[];
		filters: AggregationsItem[];
		distances: AggregationsItem[];
		themes: AggregationsItem[];
		attributes: AggregationsItem[];
		zoover_silver_award: AggregationsItem[];
		zoover_gold_award: AggregationsItem[];
		city_entity_ids: AggregationsItem[];
		country_entity_ids: AggregationsItem[];
		region_entity_ids: AggregationsItem[];
		virtual_region_entity_ids: AggregationsItem[];
		chain_entity_ids: AggregationsItem[];
	};
	results: RawCompositeSearchResults[];
	results_total: number;
}

export interface RawCompositeSearchResults {
	accommodation: Search.AccommodationCompositeSearchResponse;
	category: number;
	giata_id: number;
	name: string;
	offers: Search.RawOfferItem[];
}

export type CompositeSearchGeoFilters = Record<string, number | string | string[]>;

export type ZooverSearchResults = {
	accommodations: Search.CompactAccommodationItem[];
	aggregations: Partial<Record<AggregationType, AggregationsItem[]>>;
	totalResults: number;
};

export type ZooverCompositeSearchResults = {
	accommodations: Search.CompactAccommodationItem[];
	aggregations: Partial<Record<AggregationType, AggregationsItem[]>>;
	geoAggregations: {
		city_entity_ids: { [entityId: string]: number };
		country_entity_ids: { [entityId: string]: number };
		region_entity_ids: { [entityId: string]: number };
	};
	totalResults: number;
};
