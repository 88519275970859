export const DestinationType = {
	CITY: 'CITY',
	REGION: 'REGION',
	COUNTRY: 'COUNTRY',
} as const;

export type DestinationType = keyof typeof DestinationType;

export type Location = {
	destinationType: DestinationType;
	text: string;
	destinationSlug: string;
	to: string;
	id: string;
	heliosId: number;
};

export interface GeoEntity {
	id: string;
	name: string;
	heliosId: number;
	heliosUrl: string;
	destinationType: DestinationType;
	order?: number;
	slug?: string;
}

export interface Adjusted {
	price?: number;
	rating: number;
}

export interface ReviewCalculations {
	lastReviewDate: number;
	adjusted: Adjusted;
	perTraveledWith: PerTraveledWith;
	overall: Overall;
	perAspectGroup: Partial<PerAspectGroup>;
}

export interface PerTraveledWith {
	'FAMILY-WITH-YOUNG-CHILDREN'?: Overall;
	'FAMILY-WITH-OLDER-CHILDREN'?: Overall;
	OTHER?: Overall;
	FRIENDS?: Overall;
	SINGLE?: Overall;
	COUPLE?: Overall;
	BUSINESS?: Overall;
	FAMILY?: Overall;
	GROUP?: Overall;
}

export interface Overall {
	rating: number;
	count: number;
}

export type PerAspectGroup = Record<AspectKeys, Overall>;
export type AspectKeys =
	| 'general'
	| 'location'
	| 'service'
	| 'priceQuality'
	| 'food'
	| 'room'
	| 'childFriendly'
	| 'business'
	| 'interior'
	| 'space'
	| 'activities'
	| 'housing'
	| 'restaurants'
	| 'sanitaryState'
	| 'accessibility'
	| 'nightlife'
	| 'culture'
	| 'surrounding'
	| 'atmosphere'
	| 'environmental'
	| 'noviceSkiArea'
	| 'advancedSkiArea'
	| 'apresSki'
	| 'hiking'
	| 'beach'
	| 'entertainment'
	| 'sphere'
	| 'nature'
	| 'pool'
	| 'terrace'
	| 'hygiene';

export interface Recommendation {
	accommodationHeliosIds: string[];
	title: string;
}

export interface Geo {
	coordinates: number[];
}

export interface User {
	id: string;
}

export enum FACILITY_GROUP_NAME {
	FACILITIES = 'facilities',
	HOTEL_TYPE = 'hotel-type',
	ROOM_FACILITIES = 'room-facilities',
	PAYMENT = 'payment',
	SPORT_ENTERTAINMENT = 'sport-entertainment',
	HYGIENE = 'hygiene',
	BEACH = 'beach',
	CAMPSITE_INFORMATION = 'campsite-information',
	BUILDING_INFORMATION = 'building-information',
	DISTANCES = 'distances',
}

export interface FetchedFact {
	name: string;
	group: FACILITY_GROUP_NAME;
	key: string;
	value: string;
}

export interface FormattedFacts {
	key: string;
	name: FACILITY_GROUP_NAME;
	groups: FetchedFact[];
}

export type GeoEntityType = {
	id: string;
	name: string;
	internationalName?: string;
	shortName?: string;
	destinationType: DestinationType;
	heliosUrl: string;
	heliosId: number;
};

export interface Media {
	id: string;
	sourceUrl: string;
	title: string;
	description?: string;
	rotationDegrees: number;
}
